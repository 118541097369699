import React from 'react';
import Box from '@mui/material/Box';
import logo from '../images/scrum-federation-logo.png'

export default function Header(props) {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'top',
            }}
            >
        <Box
            component="img"
            sx={{
                minWidth: '100px',
                maxWidth: '400px',
                // height: '100%',
            }}
            alt="Scrum Federation logo"
            src={logo}
            {...props}
        />
        </Box>
    );
}
