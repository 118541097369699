import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { collection, query, where, getDocs } from "firebase/firestore";
import { signOut } from "firebase/auth";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: "scrum-federation.firebaseapp.com",
  projectId: "scrum-federation",
  storageBucket: "scrum-federation.appspot.com",
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

export const sendPasswordReset = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    alert("Password reset link sent!");
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

export const getValidatedUser = () => {
  if (auth.currentUser != null) {
    return auth.currentUser;
  }

  return new Promise((resolve, reject) => {
    const unsubscribe = auth
      .onAuthStateChanged(
        (user) => {
          unsubscribe();
          resolve(user);
        },
        reject("User not signed in.") // pass up any errors attaching the listener
      );
  });
}

export const loggedIn = () => {
    return auth.currentUser != null;
};


export const getProfile = async () => {
  // var profile = null;
  // await getValidatedUser().then(async user => {
  //   console.log("GETPROFILE::USER ID: " + user.uid);
  //   if (user) {
  //     const q = query(collection(db, "profile"), where("user_id", "==", user.uid));
  //     const snapshot = await getDocs(q);
  //     snapshot.forEach((doc) => {
  //         console.log(doc.id, " => ", doc.data());
  //         var tempProfile = doc.data();
  //         tempProfile.id = doc.id;
  //         return tempProfile;
  //     });
  //   } 
  // }).catch(error => {
  //   console.log("GETPROFILE::ERROR " + error);
  // });

  //   return profile;
  var profile = null;
  const user = await getValidatedUser();

  // console.log("USER ID: " + user.uid);
  if (user) {
    const q = query(collection(db, "profile"), where("user_id", "==", user.uid));
    const snapshot = await getDocs(q);
    snapshot.forEach((doc) => {
        // console.log(doc.id, " => ", doc.data());
        var tempProfile = doc.data();
        tempProfile.id = doc.id;
        profile = tempProfile;
        // console.log("profile.id: "+ profile.id);
    });
  } 
  return profile;
};

export const getUser = async (id) => {
  var profile = null;
  const q = query(collection(db, "profile"), where("user_id", "==", id));
  const snapshot = await getDocs(q);
  snapshot.forEach((doc) => {
      var tempProfile = doc.data();
      tempProfile.id = doc.id;
      profile = tempProfile;
  });
  return profile;
};

export const getCertificate = async (id) => {
  var certificate = null;

    const q = query(collection(db, "certificates"), where("id", "==", id));
    const snapshot = await getDocs(q);
    snapshot.forEach((doc) => {
        // console.log(doc.id, " => ", doc.data());
        var tempCertificate = doc.data();
        certificate = tempCertificate;
        // console.log("fetched certificate.id: "+ JSON.stringify(certificate));
    });
    return certificate;
};

export const handleLogout = () => {   
  return new Promise((resolve, reject) => {            
    signOut(auth).then(() => {
      resolve();
    }).catch((error) => {
      reject(error);
    });
  });
};

const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const db = getFirestore(app);

export default app;