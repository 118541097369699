import *  as React from 'react';
import { useState, useEffect } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { db, getProfile, getValidatedUser, getCertificate, auth } from '../firebase';
import { useNavigate } from 'react-router-dom';
import { collection, query, where, getDocs, updateDoc, doc, setDoc } from "firebase/firestore";
import getStripe from '../helpers/stripe';
import { ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import StarIcon from '@mui/icons-material/StarBorder';
import Typography from '@mui/material/Typography';
import GlobalStyles from '@mui/material/GlobalStyles';
import Container from '@mui/material/Container';
import CardMedia from '@mui/material/CardMedia';

import '../styles/Product.css'
import uuid from 'react-uuid';
import Footer from '../components/Footer';
import NavigationBar from '../components/NavigationBar';
import { ExamModel } from '../models/ExamModel';
import parse from 'html-react-parser';
import { Alert } from '@mui/material';
import { theme } from '../helpers/theme';

import video3 from '../videos/3.mp4';

const tiers = [
    {
      title: 'Basic',
      price: '99',
      description: [
        '1x exam attempt (for this specific exam)',
        '-',
        '70% chance of passing',
        'Certificate never expires',
        'Email support',
      ],
      buttonText: 'I got this 💪',
      buttonVariant: 'outlined',
    },
    {
      title: 'Premium',
      subheader: 'Most popular',
      price: '129',
      description: [
        '3x exam attempts (for this specific exam)',
        '-',
        '90% chance of passing',
        'Certificate never expires',
        'Email support',
      ],
      buttonText: '3 is perfect for me 👌',
      buttonVariant: 'contained',
    },
    {
      title: 'Unlimited',
      price: '149',
      description: [
        'Unlimited exam attempts (for this specific exam)',
        '-',
        'Guaranteed certificate',
        'Certificate never expires',
        'Priority email support',
      ],
      buttonText: 'No risk lets go! 🚀',
      buttonVariant: 'outlined',
    },
  ];

    
  // TODO remove, this demo shouldn't need to reset the theme.

export default function Buy() {
    const location = useLocation();
    const [certificate, setCertificate] = useState(location.state ? location.state.certificate : 0);
    const [profile, setProfile] = useState(null);
    const [credits, setCredits] = useState();
    const [activeExam, setActiveExam] = useState();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    
    var profileId = null;
    var certificateId = null;
    var validatePurchaseId = null;

    const onPageLoad = async () => {
        certificateId = searchParams.get("certificate_id") / 1;
        validatePurchaseId = searchParams.get("purchase");
        let amount = searchParams.get("amount") / 1 || 0;

        
        let tempCertificate = await getCertificate(certificateId);

        if (certificate) {
            certificateId = certificate.id;
        } else {
            setCertificate(tempCertificate);
        }

        if (auth.currentUser != null) {
            const p = await getProfile();
            if (p != null) {
                if (validatePurchaseId && 
                    validatePurchaseId.length >= 36 &&
                    p.purchase_validation && 
                    p.purchase_validation === validatePurchaseId && 
                    (amount === 1 || amount === 3 || amount === 9999)) {
                    // console.log("PURCHASED! Amount: " + amount);
                    purchasedCertificate(amount, tempCertificate);
                }
            
                setProfile(p);
                if (p.exam_credits !== undefined) {
                    setCredits(JSON.parse(p.exam_credits));
                }
                
                const e = await getActiveExam();
                setActiveExam(e);
            }
        }
    };

    const startExam = async () => {
        const user = await getValidatedUser();
        if (!user) {
            throw new Error("User not signed in!");
        }

        if (credits && credits[certificate.id] != null && credits[certificate.id]["credits"] > 0 ) {
            credits[certificate.id]["credits"] -= 1;
        } else {
            throw new Error("Not enough credits!" + profile.id);
        }

        try {    
            const docRef = doc(db, 'profile', profile.id);
            
            await updateDoc(docRef, {
                exam_credits: JSON.stringify(credits)
            });

            // create exam record for user_id 
            const exam = new ExamModel(uuid(), user.uid, certificate.id, new Date(), 902); // 15 minutes and 2 seconds to account for loading times
            await setDoc(doc(db, "exam", exam.id), {
                certificate_id: exam.certificate_id,
                user_id: exam.uid,
                start_time: exam.start_time,
                duration: exam.duration, 
                answers: exam.answers
            });
            
            navigate('/exam',
                {   
                    state: {
                        certificate: certificate,
                        exam: exam
                    }
                }
            );
        } catch (e) {
            console.error("Error adding document: ", e);
        }
    };

    const continueExam = async () => {
        const exam = new ExamModel(activeExam.id, activeExam.user_id, certificate.id, activeExam.start_time, activeExam.duration);
        navigate('/exam',
            {   
                state: {
                    certificate: certificate,
                    exam: exam
                }
            }
        );
    };

    useEffect(() => {
        if (document.readyState === 'complete') {
            onPageLoad();
        } else {
            window.addEventListener('load', onPageLoad);
            // Remove the event listener when component unmounts
            return () => window.removeEventListener('load', onPageLoad);
        }
    // eslint-disable-next-line
    }, []);

    const getActiveExam = async () => {
        var examData = null;
        const user = await getValidatedUser();
        // console.log("getActiveExam::USER: " + user.uid);
        // console.log("getActiveExam::certificate.id: " + certificateId);
        if (user) {
            const q = query(collection(db, "exam"), where("user_id", "==", user.uid), where("certificate_id", "==", certificateId));
            const snapshot = await getDocs(q);
            snapshot.forEach((doc) => {
                if (!doc.data().finished) {
                    // console.log(doc.id, " => ", doc.data());
                    var temp = doc.data();
                    const startTime = temp.start_time["seconds"] * 1000;
                    var endTime = new Date(startTime + temp.duration * 1000);
                    const now = new Date();
                    if (endTime > now) {
                        examData = temp;
                        examData.start_time = new Date(startTime);
                    }
                    // console.log("exam start time: "+ endTime);
                }
            });
        }
        // console.log("EXAM: " + JSON.stringify(examData));
        return examData
    };

    const hasCredits = () => {
        if (credits && credits[certificate.id] != null ) {
            return credits[certificate.id]["credits"] > 0;
        }
        return false;
    };

    const creditsForCategoryId = () => {
        if (credits && credits[certificate.id] != null ) {
            const amountOfCredits = credits[certificate.id]["credits"];

            if (amountOfCredits > 999) {
                return "∞";
            }

            return credits[certificate.id]["credits"];
        }

        return 0;
    };

    async function handleCheckout(index) {
        let purchase_validation = uuid();
        
        // console.log("profile: " + JSON.stringify(profile));
        if (auth.currentUser != null) {
            const profile = await getProfile();
            const docRef = doc(db, 'profile', profile.id);
            await updateDoc(docRef, {
                purchase_validation: purchase_validation
            });
            const user = auth.currentUser;
            const stripe = await getStripe();
            var price = certificate.products[index].price;
            
            var baseUrl = window.location.protocol + "//" + window.location.hostname;
            if (process.env.NODE_ENV === 'development') {
                baseUrl += ":" + window.location.port;
                price = certificate.products[index].test;
            }

            const { error } = await stripe.redirectToCheckout({
            lineItems: [
                {
                price: price,
                quantity: 1,//certificate.products[index].amount,
                },
            ],
            mode: 'payment',
            successUrl: baseUrl + "/buy?certificate_id=" + certificate.id + "&purchase=" + purchase_validation + "&amount=" + certificate.products[index].amount,
            cancelUrl: baseUrl + "/buy?certificate_id=" + certificate.id,
            customerEmail: user.email,
            });
            console.error(error.message);
        } else {
            navigate('/login',
                { state: {
                    redirect: '/buy',
                    certificate: certificate,
                    buy: index,
                    message: 'Please login/ register before making a purchase. You\'ll get redirected to the correct page after you\'ve done this.'
                  }
                }
            );
        }
      }

    const purchasedCertificate = async (amount, tempCertificate) => {
        const user = auth.currentUser;
        // console.log("Purchased " + amount);
        if (!user) {
            throw new Error("User not signed in!");
        }

        if (!certificate && !tempCertificate) {
            throw new Error("No certificate data!");
        }
        // console.log("User ID: " + user.uid);
        if (user.uid) {
            const q = query(collection(db, "profile"), where("user_id", "==", user.uid));
            var editedProfile = null;
            const snapshot = await getDocs(q);
            snapshot.forEach((doc) => {
                // console.log(doc.id, " => ", doc.data());
                profileId = doc.id;
                editedProfile = doc.data();
                var credits = JSON.parse(editedProfile.exam_credits);
                if (credits[certificateId] != null ) {
                    credits[certificateId]["credits"] += amount
                } else {
                    credits = {...credits,
                        [certificateId] : {"credits": amount}
                    }
                }
                editedProfile.exam_credits = JSON.stringify(credits);
            });
        }

        // TODO: uncomment after testing is complete

        try {
            const docRefValidation = doc(db, 'profile', profileId);
            await updateDoc(docRefValidation, {
                purchase_validation: ""
            });
        } catch (e) {
            console.error("Error adding document: ", e);
        }

        // console.log("Profile " + profileId);
        try {
            const docRef = doc(db, 'profile', profileId);
            // console.log("credits: " + editedProfile.exam_credits);
            await updateDoc(docRef, {
                exam_credits: editedProfile.exam_credits
            });
            setCredits(JSON.parse(editedProfile.exam_credits));
        } catch (e) {
            console.error("Error adding document: ", e);
        }
    };

    const hasPassedExam = () => {
        // console.log("certificates: " + JSON.stringify(profile));
        if (profile !== null && profile.certificates) {
            return profile.certificates.includes(certificate.id);
        }

        return false;
    };

    const hasPrerequisite = () => {
        return certificate.prerequisite > 0;
    };

    const meetsPrerequisite = () => {
        if (certificate.prerequisite > 0 && profile !== null && profile.certificates) {
            return profile.certificates.includes(certificate.prerequisite);
        }
        return true;
    };

    const getPrerequisiteName = () => {
        switch (certificate.prerequisite) {
            case 1:
            case 4:
            case 7:
                return "CORE";
            case 2:
            case 5: 
            case 8:
                return "ADVANCED";
            default:
                return "";
        }
    }

    const renderAction = (onlyActive) => {
        
        if (activeExam) {
            return (
                <>
                    <center>
                        <Button variant="contained" color="primary" className='product-button' onClick={() => continueExam()}>
                            Continue exam
                        </Button>
                    </center>
                </>
            );
        } else if (hasPassedExam()) {
            return (
                <>
                    <center>
                        <Button variant="contained" color="primary" className='product-button' disabled>
                            You already have this certificate
                        </Button>
                    </center>
                </>
            );
        } else if (!meetsPrerequisite()) {
            return (
                <>
                    <center>
                        <Button variant="contained" color="primary" className='product-button' disabled>
                            You need to get the {getPrerequisiteName()} certificate first!
                        </Button>
                    </center>
                </>
            );
        } else if (credits && hasCredits()) {
            return (
                <>
                    <center>
                        <Button variant="contained" color="primary" className='product-button' onClick={() => startExam()}>
                            Start exam (credits: {creditsForCategoryId()})
                        </Button>
                    </center>
                </>
            );
        } else if (onlyActive) {
            if (hasPrerequisite()) {
                return (<Alert severity="success">You need to get the {getPrerequisiteName()} certificate first!</Alert>);
            }
            return (<></>);
        }

        return (
            <Container maxWidth="md" component="main">
                  <Grid container spacing={5} alignItems="flex-end">
                    {tiers.map((tier, index) => (
                      // Enterprise card is full width at sm breakpoint
                      <Grid
                        item
                        key={tier.title}
                        xs={12}
                        sm={tier.title === 'Unlimited' ? 12 : 6}
                        md={4}
                      >
                        <Card>
                          <CardHeader
                            title={tier.title}
                            subheader={tier.subheader}
                            titleTypographyProps={{ align: 'center' }}
                            action={tier.title === 'Premium' ? <StarIcon sx={{fill: "white"}} /> : null}
                            subheaderTypographyProps={{
                              align: 'center',
                            }}
                            sx={{
                              backgroundColor: (theme) =>
                              tier.title !== 'Premium' 
                                  ? theme.palette.grey[200]
                                  : theme.palette.grey[400],
                            }}
                          />
                          <CardContent>
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'baseline',
                                mb: 2,
                              }}
                            >
                              <Typography component="h2" variant="h3" color="text.primary">
                                €{certificate.prices[index]}
                              </Typography>
                            </Box>
                            <ul>
                              {tier.description.map((line) => (
                                <Typography
                                  component="li"
                                  variant="subtitle1"
                                  align="center"
                                  key={line}
                                >
                                  {line}
                                </Typography>
                              ))}
                            </ul>
                          </CardContent>
                          <CardActions>
                            <Button fullWidth variant={tier.buttonVariant} onClick={() => handleCheckout(index)}>
                              {tier.buttonText}
                            </Button>
                          </CardActions>
                        </Card>
                      </Grid>
                    ))}
                  </Grid>
                </Container>
        );
    };

    return (
        <>
            {certificate && certificate.prices ? (
                <ThemeProvider theme={theme}>
                <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
                <CssBaseline />
                <NavigationBar />
                <CardMedia
                        component='video'
                        // className={classes.media}
                        image={video3}
                        autoPlay
                        sx={{ position: 'absolute', maxWidth: '901px', zIndex: '-1', left: '50%',  transform: 'translate(-50%,-0%)'}}
                        />
                {/* Hero unit */}
                <Container disableGutters maxWidth="md" component="main" sx={{ pt: 8, pb: 6, mt: '303px', mb: '20px' }}>
                    
                  <Typography
                    component="h1"
                    variant="h3"
                    align="center"
                    color="text.primary"
                    sx={{backgroundColor: '#ffffffaa'}}
                  >
                    {certificate.title}
                  </Typography>
                  <Typography variant="div" className="quote" align="center" color="text.secondary" component="p" sx={{fontSize: '26pt', backgroundColor: '#ffffffaa', mb: '30px'}}>
                    Get your certification in just 15 minutes!
                  </Typography>
                  { renderAction(true) }
                  <Typography variant="h4" align="left" color="text.secondary" component="p" sx={{mt: '60px', mb: '50px'}}>
                    {certificate.subtitle}
                  </Typography>
                  <Typography variant="div" align="left" color="text.secondary" component="p">
                    { parse(certificate.description + "<h4>Raise your hourly rate</h4><Typography variant='div' color='text.secondary' component='p'>Having a <strong>Scrum certification</strong> can significantly enhance your professional value and <strong>increase your earning potential</strong>. With this certification, you gain a <strong>recognized and respected credential</strong> that showcases your expertise in <strong>Agile project management</strong> and proficiency in <strong>Scrum methodologies</strong>.</Typography><p><Typography variant='div' color='text.secondary' component='p'>Employers and clients often place a premium on certified professionals, recognizing the <strong>value</strong> they bring to the table. As a result, holding a Scrum certification can empower you to <strong>negotiate higher rates</strong> for your services, reflecting the <strong>specialized knowledge</strong> and <strong>proven abilities</strong> you possess. The certification serves as a testament to your <strong>commitment to excellence</strong> and can be a compelling factor in justifying higher rates, thereby opening doors to more lucrative opportunities and rewarding financial returns.</Typography></p>") }
                  </Typography>
                </Container>
                {/* End hero unit */}
                { renderAction(false) }
                <Footer />
                
              </ThemeProvider>
            ) : (
                <h1>...</h1>
            )}
        </>
    );
}