import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import CountdownTimer from '../components/CountdownTimer';
import { auth, db, getValidatedUser, getProfile } from '../firebase';
import { updateDoc, doc } from "firebase/firestore";
import { collection, query, where, getDocs, setDoc } from "firebase/firestore";
import { Button } from '@mui/material';
import uuid from 'react-uuid';

export default function Exam() {
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [showScore, setShowScore] = useState(false);
    const [timeIsUp, setTimeIsUp] = useState(false);
    const [answers, setAnswers] = useState([]);
    const [totalChangedAnswers, setTotalChangedAnswers] = useState(0);
    const location = useLocation();
    const [certificate] = useState(location.state.certificate);
    const [exam] = useState(location.state.exam);
    const [certificationId, setCertificationId] = useState();

    // const [status, setStatus] = useState('loading');

    const navigate = useNavigate();
    

    const passingGrade = certificate.passing_grade;
    const startTime = exam.start_time.getTime();
    var endTime = startTime + exam.duration * 1000;
    
    const handleAnswerOptionClick = async (answer) => {
        var temporaryAnswers = answers;
        temporaryAnswers[currentQuestion] = answer;

        setAnswers(temporaryAnswers);
        setTotalChangedAnswers(totalChangedAnswers + 1);

        const nextQuestion = currentQuestion + 1;
        if (nextQuestion < questions.length) {
            await new Promise(resolve => setTimeout(resolve, 200));
            setCurrentQuestion(nextQuestion);
        }
    };

    const hasAlreadyBeenSubmitted = async () => {
        const user = await getValidatedUser();
        const q = query(collection(db, "exam"), where("user_id", "==", user.uid));
        const snapshot = await getDocs(q);
        var submitted = false;
        snapshot.forEach((doc) => {
            if (doc.id === exam.id) {
                submitted = doc.data().finished || false;
            }
        });

        return submitted;
    }; 

    const submitAnswers = async () => {
        let submitted = await hasAlreadyBeenSubmitted();
        if (submitted) {
            return;
        }

        const docRef = doc(db, 'exam', exam.id);
        // console.log("SUBMITTING EXAM: " + exam.id)

        await updateDoc(docRef, {
            end_time: new Date(),
            answers: JSON.stringify(answers),
            questions: JSON.stringify(questions),
            finished: true,
            correct_answers: calculateScore(),
            score: calculateScore() / questions.length,
            passed: passedExam()
        });

        if (passedExam()) {
            let profile = await getProfile();
            const docRefProfile = doc(db, 'profile', profile.id);
            let certificates = profile.certificates || [];
            certificates = [...certificates, certificate.id];
            await updateDoc(docRefProfile, {
                certificates: certificates
            });
            const tempId = uuid();
            setCertificationId(tempId);
            await setDoc(doc(db, "certification", uuid()), {
                certificate_id: exam.certificate_id,
                user_id: exam.uid,
                date: new Date(),
                exam_id: exam.id,
                id: tempId,
                passing_grade: passingGrade,
                grade: calculateScore() / questions.length
            });

            sendCongratulationsEmail();
        }
    };

    const sendCongratulationsEmail = () => {
        // var myHeaders = new Headers();
        // myHeaders.append("Accept", "application/json");
        // myHeaders.append("Content-Type", "application/json");
        // myHeaders.append("Authorization", "Bearer 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");
        // myHeaders.append("Access-Control-Allow-Origin", "*");
        // myHeaders.append("Access-Control-Allow-Methods", "POST");
        // myHeaders.append("Access-Control-Allow-Headers", "Authorization, Content-Type, On-behalf-of, x-sg-elas-acl");
        // myHeaders.append("X-No-CORS-Reason", "https://sendgrid.com/docs/Classroom/Basics/API/cors.html");
        // myHeaders.append("Access-Control-Max-Age", "600");
        // myHeaders.append("Strict-Transport-Security", "max-age=600; includeSubDomains");

        // var raw = JSON.stringify({
        // "personalizations": [
        //     {
        //     "to": [
        //         {
        //         "email": "deejfit@gmail.com",
        //         "name": "David Jupijn"
        //         }
        //     ],
        //     "subject": "Congrats you passed the exam for Accredited Scrum Developer CORE! 🥳"
        //     }
        // ],
        // "from": {
        //     "email": "certification@scrumfederation.org",
        //     "name": "David | Scrum Federation"
        // },
        // "content": [
        //     {
        //     "type": "text/plain",
        //     "value": "Dear David, \n\n Great job you passed the exam for: Accredited Scrum Developer CORE! 🥳 \n\nWith kind regards,\nDavid"
        //     }
        // ]
        // });

        // var requestOptions = {
        // method: 'POST',
        // headers: myHeaders,
        // body: raw,
        // redirect: 'follow'
        // };

        // fetch("https://api.sendgrid.com/v3/mail/send", requestOptions)
        // .then(response => response.text())
        // .then(result => console.log(result))
        // .catch(error => console.log('error', error));
    };

    const handleSubmitClick = async () => {
        endTime = null;
        await submitAnswers();
        setShowScore(true);
    };

    const handleShowResultsClick = () => {
        setTimeIsUp(false);
        endTime = null;
        setShowScore(true);
    };

    const goHome = () => {
        navigate('/');
    };

    const goToCertificate = async () => {
        // let profile = await getProfile();
        // navigate('/certificate',
        //     { state: {
        //         certificateId: certificate.id, 
        //         fullName: profile.fullName,
        //         date: new Date(),
        //         // uniqueId: "this-is-a-unique-id"
        //     }
        // });
        navigate('/certificate?id=' + certificationId);
    }

    const handleNavigationBetweenQuestionsClick = (back) => {
        const add = back ? -1 : 1

        const question = currentQuestion + add;
        if (question >= 0 && question < questions.length) {
            setCurrentQuestion(question);
        }
    };

    const handleTimeIsUpEvent = async () => {
        if (!showScore) {
            endTime = null;
            await submitAnswers();
            setTimeIsUp(true);
        }
    }

    const passedExam = () => {
        if (process.env.NODE_ENV === 'development') {
            return true;
        }
        return calculateScore() / questions.length >= passingGrade;
    };

    const isFirstQuestion = () => {
        return currentQuestion === 0;
    }

    const isLastQuestion = () => {
        return currentQuestion === questions.length - 1;
    }

    const allQuestionsAnswered = () => {
        if (process.env.NODE_ENV === 'development') {
            return true;
        }

        for (const answer of answers) {
            if (answer === "") {
                return false;
            }
        }
        return answers.length === questions.length;
    }

    const calculateScore = () => {
        var score = 0;
        var index = 0;
        for (var answer of answers) {
            if (answer === questions[index].correctAnswer) {
                score += 1;
            }
            index += 1;
        }
        return score;
    }

    const getCorrectQuestionsForExam = () => {
        // console.log("Waar ga je fout?" + certificate.exam);
        return JSON.parse(certificate.exam);
    }
    
    const getLetterForIndex = (index) => {
        return (index + 10).toString(36).toUpperCase();
    };

    var questions = getCorrectQuestionsForExam();

    useEffect(() => {
        const securityCheck = async () => {
            // console.log("Security check");
            var examData = null;
            const user = await getValidatedUser();
            if (user) {
                const q = query(collection(db, "exam"), where("user_id", "==", user.uid));
                const snapshot = await getDocs(q);
                // console.log("------------EXAM ID------------  " + exam.id);
                // console.log("user: " + user.uid);
                snapshot.forEach((doc) => {
                    if (doc.id === exam.id) {
                        // console.log(doc.id, " => ", doc.data());
                        var temp = doc.data();
                        examData = temp;
                        // console.log("exam: "+ doc.id);
                    }
                });

                if (examData != null && examData.finished) {
                    // console.log("docRef" + JSON.stringify(examData));
                    setAnswers(JSON.parse(examData.answers));
                    // questions = JSON.parse(examData.questions);
                    setShowScore(true);
                } 
            }
        };

        if (document.readyState === 'complete') {
          securityCheck();
        } else {
          window.addEventListener('load', securityCheck);
          // Remove the event listener when component unmounts
          return () => window.removeEventListener('load', securityCheck);
        }
        // eslint-disable-next-line
      }, []);

    useEffect(() => {
        if (answers.length === 0 && auth.currentUser != null) {
            var temporaryAnswers = answers;
            for (let i = 0; i < questions.length; i++) {
                temporaryAnswers.push("");
            }
            setAnswers(temporaryAnswers);
        }
    }, [answers, questions.length]);

    return (
        
        <>
        {timeIsUp ? (
            <div>
            <h1>Time's up. Your answers have been submitted.</h1>
                
            <Button className='submit-button' onClick={() => handleShowResultsClick()} variant='contained'>Show results</Button>
            </div>
        ) : (
        <>
            { !showScore && <CountdownTimer targetDate={endTime} onFinished={handleTimeIsUpEvent} /> }
            <h2><center>Exam: {certificate.title}</center></h2>
            <div className='app'>
                {showScore ? (
                    <div className='score-section'>
                        You scored {calculateScore()} out of {questions.length}
                        {passedExam() ? (
                            <>
                                <div className='score-text'>Congratulations you passed the exam! 🥳</div>
                                <Button onClick={() => goToCertificate()} variant='contained'>Get your certificate</Button>
                            </>
                        ) : (
                            <>
                                <div className='score-text'>You failed the exam. 😢</div>
                                <Button onClick={() => goHome()} variant='contained'>Home</Button>
                            </>
                            )
                        }
                    </div>
                ) : (
                    <>
                        <div className='question-section'>
                            <div className='question-count'>
                                <span>Question {currentQuestion + 1}</span>/ {questions.length}
                            </div>
                            <div className='question-text'>{questions[currentQuestion].question}</div>
                        </div>
                        <div className='answer-section'>
                            {questions[currentQuestion].answers.map((answer, index) => (
                            // {questions[currentQuestion].answers.sort(() => Math.random() - 0.5).map((answer, index) => (
                                <button key={"answer" + index} 
                                        className={answers[currentQuestion] === answer ? "selected-answer": ""} 
                                        onClick={() => handleAnswerOptionClick(answer)}>
                                    <span className='button-answer-icon'>{getLetterForIndex(index)}</span> {answer}
                                </button>
                            ))}
                        </div>

                        <div className='question-navigation-section'>
                            <button className='question-navigation-button' 
                                    onClick={() => handleNavigationBetweenQuestionsClick(true)} 
                                    disabled={isFirstQuestion()}>←</button>
                            <button className='question-navigation-button' 
                                    onClick={() => handleNavigationBetweenQuestionsClick(false)} 
                                    disabled={isLastQuestion()}>→</button>
                        </div>

                        <div className='question-navigation-section'>
                            <Button className='submit-button' onClick={() => handleSubmitClick(true)} disabled={!allQuestionsAnswered()} variant='contained'>Submit Answers</Button>
                        </div>                        
                    </>
                )}
            </div>
        </>
        )}
        </>
    );
}
