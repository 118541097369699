export class ExamModel {
  constructor(id, uid, certificate_id, start_time, duration) {
    this.id = id;
    this.certificate_id = certificate_id;
    this.uid = uid;
    this.start_time = start_time;
    this.duration = duration;
    this.answers = [];
  }
  toString() {
    return this.id + ', ' + this.certificate_id + ', ' + this.uid;
  }
}
  
  