import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { auth, sendPasswordReset } from "../firebase";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockReset from '@mui/icons-material/LockReset';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { ThemeProvider } from '@mui/material/styles';
import Footer from '../components/Footer';
import Header from '../components/Header';

import "../styles/Reset.css";
import { theme } from "../helpers/theme";

function Reset() {
  const user = auth.currentUser;
  const [email, setEmail] = useState("");
  const navigate = useNavigate();

  const onReset = (e) => {
    e.preventDefault();
    // console.log("Sending email for reset with address: " + email);
    sendPasswordReset(email);
  };

  useEffect(() => {
    if (user) navigate("/");
  }, [user, navigate]);
  return (
    <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
            <Header />
            <CssBaseline />
            <Box
            sx={{
                marginTop: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
            >
          <Avatar sx={{ m: 1, bgcolor: 'primary.main'}}>
            <LockReset sx={{ fill: "white"}} />
          </Avatar>
          <Typography component="h1" variant="h5">
            Reset password
          </Typography>
          <Box component="form" onSubmit={onReset} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              onChange={(e) => setEmail(e.target.value)} 
              value={email}
              autoFocus
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Send password reset email
            </Button>
            <Grid container>
                <Grid item>
                <NavLink to="/signup" variant="body2">
                    No account yet? Sign up
                </NavLink>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Footer sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
    // <div className="reset">
    //   <div className="reset__container">
    //     <input
    //       type="text"
    //       className="reset__textBox"
    //       value={email}
    //       onChange={(e) => setEmail(e.target.value)}
    //       placeholder="E-mail Address"
    //     />
    //     <button
    //       className="reset__btn"
    //       onClick={() => sendPasswordReset(email)}
    //     >
    //       Send password reset email
    //     </button>
    //     <div>
    //       Don't have an account? <Link to="/register">Register</Link> now.
    //     </div>
    //   </div>
    // </div>
  );
}
export default Reset;