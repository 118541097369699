import React from 'react';
import '../styles/CountdownTimer.css'

export default function TimeDisplay({ value, type, isDanger }) {
  return (
    <div className={isDanger ? 'countdown danger' : 'countdown'}>
      <p>{value}</p>
      <span>{type}</span>
    </div>
  );
};