import React, { useEffect, useRef, useState } from 'react';
import jsPDF from 'jspdf';
import dayjs from 'dayjs';
import uuid from 'react-uuid';

import certificate1 from '../certificates/1.png';
import certificate2 from '../certificates/2.png';
import certificate3 from '../certificates/3.png';
import certificate4 from '../certificates/4.png';
import certificate5 from '../certificates/5.png';
import certificate6 from '../certificates/6.png';
import certificate7 from '../certificates/7.png';
import certificate8 from '../certificates/8.png';
import certificate9 from '../certificates/9.png';

import { collection, query, where, getDocs } from "firebase/firestore";
import { db, getUser } from '../firebase';

import pinyonScript from '../fonts/PinyonScript-Regular.ttf';
import montserrat from '../fonts/Montserrat-SemiBold.ttf';
import { useSearchParams } from 'react-router-dom';
import NavigationBar from '../components/NavigationBar';
import Footer from '../components/Footer';
import { Button } from '@mui/material';

export default function Certificate(props) {
  const canvasRef = useRef(null);
  
  const [fullName, setFullName] = useState("");
  const [certificateId, setCertificateId] = useState();
  const [certificationDate, setCertificationDate] = useState(new Date());
  const [searchParams] = useSearchParams();

  const certificates = [certificate1, certificate2, certificate3, certificate4, certificate5, certificate6, certificate7, certificate8, certificate9];
  
  const certificateImage = new Image();
 
  const getCertificate = async (uid) => {
    const q = query(collection(db, "certification"), where("id", "==", uid));
    const snapshot = await getDocs(q);
    var userId = "";
    snapshot.forEach((doc) => {
      // console.log(doc.id, " => ", doc.data());
      var temp = doc.data();
      setCertificationDate(temp.date.toDate());
      setCertificateId(temp.certificate_id);
      userId = temp.user_id;
      // console.log("date: "+ certificationDate);
      // console.log("certificate_id: "+ certificateId);
      certificateImage.src = certificates[certificateId-1];
    });

    const profile = await getUser(userId);
    if (profile) {
      setFullName(profile.fullName);
    }
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    
    var certificateUniqueIdentifier = uuid();
    var uid = "";

    if (searchParams.get("id")) {
      uid = searchParams.get("id");
    }
    
    if (uid !== "") {
      certificateUniqueIdentifier = uid;
      
      getCertificate(uid);
    }
    
    const findFontSizeToFit = (text, width, height) => {
      let fontSize = 84;

      while (true) {
        ctx.font = `${fontSize}px Arial`;
        const textWidth = ctx.measureText(text).width;

        if (textWidth <= width && fontSize <= height) {
          return fontSize;
        }

        fontSize--;
      }
    };

    function drawTextBG(ctx, txt, font, x, y, padding) {
      ctx.font = font;
      ctx.textBaseline = "top";
      ctx.fillStyle = "#ffffff00";
    
      var width = ctx.measureText(txt).width;
      ctx.fillRect(x - width/2 - padding/2, y, width + padding, parseInt(font, 10) + padding);
    
      ctx.lineWidth = 0.5;
      ctx.strokeStyle = "#474747";
      ctx.strokeRect(x - width/2 - padding/2, y, width + padding, parseInt(font, 10) + padding);
    
      ctx.fillStyle = "#474747";
      ctx.fillText(txt, x, y + (padding/2));
    }

    const fontSizeToFit = findFontSizeToFit(fullName, 1200, 140);

    const fontPromises = [
      new FontFace('PinyonScript', 'url(' + pinyonScript + ')').load(),
      new FontFace('Montserrat', 'url(' + montserrat + ')').load()
    ];

    certificateImage.onload = () => {
      Promise.all(fontPromises).then((fonts) => {
        fonts.forEach((font) => document.fonts.add(font));

        ctx.drawImage(certificateImage, 0, 0, canvas.width, canvas.height);

        const canvasWidth = canvas.width;
 
        // Center text
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';

        // Add date 
        const certDate = dayjs(certificationDate).format('D MMMM YYYY');
        ctx.font = '26px Montserrat';
        ctx.fillStyle = '#004aad';
        ctx.fillText(certDate, 428, 745);

        // Calculate text position
        const textX = canvasWidth / 2;
        const textY = 340;

        // Add fullName
        ctx.font = fontSizeToFit + 'px PinyonScript';
        ctx.fillStyle = '#474747';
        ctx.fillText(fullName, textX, textY);

         // Add uuid 
         const certificateId = 'Certificate ID: ' + certificateUniqueIdentifier;
         drawTextBG(ctx, certificateId, '12px Montserrat', textX, 846, 8);
      });
    };
    // eslint-disable-next-line
  }, [certificateId, fullName]);

  const downloadPdf = () => {
    const pdf = new jsPDF('landscape');
    const imageData = canvasRef.current.toDataURL('image/jpeg', 1.0);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();

    pdf.addImage(imageData, 'JPEG', 0, 0, pdfWidth, pdfHeight);
    pdf.save('certificate.pdf');
  };

  return (
    <>
      <NavigationBar />
      <h1>Certification</h1>
      <div><center className='quote'>Congratulations on passing the exam and getting certified! 🥳</center></div>
      <br />
      <br />
      <center><Button variant="contained" onClick={() => downloadPdf()} sx={{maxWidth: '300px', alignItems: 'center', my: '20px'}}>Download Certificate</Button></center>
      <canvas ref={canvasRef} width={1250} height={875}/>
      <center><Button variant="contained" onClick={() => downloadPdf()} sx={{maxWidth: '300px', alignItems: 'center', my: '20px'}}>Download Certificate</Button></center>
      <Footer />
    </>
  );
}