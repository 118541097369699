import React from 'react';
import TimeDisplay from './TimeDisplay';
import '../styles/CountdownTimer.css'

export default function ShowCount({ minutes, seconds }) {
  return (
    <div className="show-counter">
      <TimeDisplay value={minutes} type={'Mins'} isDanger={minutes < 5} />
      <p>:</p>
      <TimeDisplay value={seconds} type={'Seconds'} isDanger={minutes < 5} />
    </div>
  );
};
