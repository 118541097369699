import { createTheme } from '@mui/material';
import { lightBlue, deepOrange } from '@mui/material/colors';

export const theme = createTheme({
  palette: {
    primary: {
        main: lightBlue[600],
    },
    secondary: {
        main: deepOrange[300],
    },
  },
});