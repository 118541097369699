import * as React from 'react';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views-react-18-fix';
import { autoPlay } from 'react-swipeable-views-utils-react-18-fix';
import { theme } from '../helpers/theme';
import parse from 'html-react-parser';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const testimonials = [
  {
    author: 'John D.',
    testimonial:
        "Scrum Federation's certification process was <strong>smooth and hassle-free</strong>. I appreciated the <strong>cost-effective approach</strong>, as it allowed me to focus on the exam without breaking the bank. The certification I received has already made a <strong>positive impact on my career prospects</strong>.",
  },
  {
    author: 'Sarah P.',
    testimonial:
        "I was skeptical at first, but Scrum Federation exceeded my expectations. The <strong>convenience of studying at my own pace</strong> and taking the exam online was a <strong>game-changer</strong>. I passed the exam and received my certification within minutes. <strong>Highly recommended</strong>!",
  },
  {
    author: 'Dave M.',
    testimonial:
        "Scrum Federation's <strong>expertly crafted exam</strong> truly assessed my understanding of Scrum principles. It challenged me and validated my skills. The certification I obtained has been instrumental in landing new opportunities and <strong>boosting my credibility</strong> in the Agile community.",
  },
  {
    author: 'Lisa S.',
    testimonial:
        "Choosing Scrum Federation was a smart move for my career. The <strong>flexibility to study and take the exam on my own terms</strong> was invaluable. The process was <strong>efficient, affordable</strong>, and the certification has opened doors I never thought possible. Thank you, Scrum Federation!",
  },
  {
    author: 'Michael T.',
    testimonial:
        "I had been searching for a Scrum certification that didn't require <strong>expensive training programs</strong>. Scrum Federation was the perfect solution. The certification I received has already <strong>opened doors</strong> and <strong>enhanced my professional credibility</strong>. Thank you, Scrum Federation!",
  },
];

function TestimonialCarousel(props) {
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = testimonials.length;

  const handleNext = () => {
    if (activeStep + 1 > maxSteps - 1) {
        setActiveStep(() => 0);
    } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    if (activeStep - 1 < 0) {
        setActiveStep(() => maxSteps - 1);
    } else {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <Box {...props} sx={{ flexGrow: 1, bgcolor: "#eeeeee" }}>
      
      <AutoPlaySwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {testimonials.map((step, index) => (
          <div key={step.author}>
            {Math.abs(activeStep - index) <= 2 ? (
              <Typography variant="h6"
                sx={{
                  mt: '20px',
                  display: 'block',
                  overflow: 'hidden',
                  width: '100%',
                }}
              >
                {parse("<i>" + step.testimonial + "</i>")}
            </Typography>
            ) : null}
          </div>
        ))}
      </AutoPlaySwipeableViews>
      <Paper
        square
        elevation={0}
        sx={{
          textAlign: 'center',
          mt: '10px',
          bgcolor: "#eee" 
        }}
      >
        <Typography variant="div" className='quote' textAlign='center' sx={{}}>{testimonials[activeStep].author}</Typography>
      </Paper>
      <MobileStepper
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
          >
            {theme.direction === 'rtl' ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack}>
            {theme.direction === 'rtl' ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
          </Button>
        }
        sx={{bgcolor: "#eee" }}
      />
    </Box>
  );
}

export default TestimonialCarousel;