import React from 'react';
import { useCountdown } from '../hooks/UseCountdown';
import ShowCount from './ShowCount';
import ExpiredNotice from './ExpiredNotice';
import '../styles/CountdownTimer.css'
import { useEffect } from 'react';

export default function CountdownTimer ({ targetDate, onFinished }) {
    const [minutes, seconds] = useCountdown(targetDate);

    useEffect(() => {
      if (minutes + seconds <= 0) {
          onFinished(true);
      }
    }, [minutes, seconds, onFinished]);

    if (minutes + seconds <= 0) {
      return <ExpiredNotice />;
    } else {
      return (
        <ShowCount
          minutes={minutes}
          seconds={seconds}
        />
      );
    }
  };
