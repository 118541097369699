import React from 'react';
import { Link, Typography } from '@mui/material';
// import iso9001Compliant from '../images/iso-9001-compliant.png';
import parse from 'html-react-parser';

export default function Footer(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" sx={{mt: '34px', mb: '24px', display: 'flex', alignItems:'center', justifyContent: 'center'}} {...props}>
            {parse('Copyright © &nbsp;')}
            <Link color="inherit" href="https://scrumfederation.org/">
            {parse('Scrum Federation')}
            </Link>
            {parse('&nbsp;')}
            {new Date().getFullYear()}
            {'.'}
            {/* <Box
                    component="img"
                    sx={{
                        maxWidth: '70px',
                    }}
                    alt="iso 9001 compliant"
                    src={iso9001Compliant}
                /> */}
        </Typography>
    );
}