import React, {useState, useEffect} from 'react';
// import Text from '../components/elements/Text';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../firebase';
import { useLocation, useNavigate } from 'react-router-dom'
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { ThemeProvider } from '@mui/material/styles';
import Footer from '../components/Footer';
import Header from '../components/Header';
import { LoginOutlined } from '@mui/icons-material';
import { Alert, Link } from '@mui/material';
import { theme } from '../helpers/theme';


export default function Login() {
    const navigate = useNavigate();
    const location = useLocation();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [redirect, setRedirect] = useState('');
    const [certificate, setCertificate] = useState(null);
    const [buy, setBuy] = useState(0);
    const [message, setMessage] = useState('');
       
    const user = auth.currentUser;
    
    useEffect(() => {
        if (user != null) {
            navigate("/");
            // console.log(user);
        }
    }, [user, navigate]);

    useEffect(() => {
      if (location != null && location.state != null) {
        setRedirect(location.state.redirect || '');
        setCertificate(location.state.certificate || null);
        setBuy(location.state.buy || 0);
        setMessage(location.state.message || '');
      }
    }, [location]);

    const onLogin = (e) => {
        e.preventDefault();
        signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            // Signed in 
            const user = userCredential.user;
            if (redirect !== '') {
              navigate(redirect,
                { state: {
                  certificate: certificate,
                  buy: buy
                }});
            } else {
              navigate("/");
            }
            console.log(user.displayName);
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorCode, errorMessage)
        });
    }

    const goToSignUp = () => {
      if (redirect !== '') {
        navigate("/signup",
        { state: {
            redirect: redirect,
            certificate: certificate,
            buy: buy,
            message: message
          }
        });
      } else {
        navigate("/signup");
      }
    };

    const goToReset = () => {
        navigate("/reset");      
    };

    return(
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
            <Header />
            <CssBaseline />
            <Box
            sx={{
                marginTop: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
            >
          { message && 
            <Alert severity="success">{message}</Alert>
          }
          <Avatar sx={{ m: 1, bgcolor: 'primary.main'}}>
            <LoginOutlined sx={{ fill: "white"}} />
          </Avatar>
          <Typography component="h1" variant="h5">
            Log in
          </Typography>
          <Box component="form" onSubmit={onLogin} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              onChange={(e) => setEmail(e.target.value)} 
              value={email}
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={(e) => setPassword(e.target.value)} 
              value={password}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Log in
            </Button>
            <Grid container>
                <Grid item xs={5}>
                    <Link className="pointer" onClick={goToReset} variant="body2">
                        Forgot password?
                    </Link>
                </Grid>
                <Grid item xs={2}>
                    &nbsp;
                </Grid>
                <Grid item xs={5}>
                <Link className="pointer" onClick={goToSignUp} variant="body2">
                    No account yet? Sign up
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Footer sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
    )
}
