import *  as React from 'react';
import { useState, useEffect } from 'react';
import ProductCard from '../components/ProductCard';
import { db } from '../firebase';
import { useNavigate } from 'react-router-dom';
import { collection, query, getDocs } from "firebase/firestore";

import '../styles/Store.css'
import NavigationBar from '../components/NavigationBar';
import Footer from '../components/Footer';
import { CssBaseline, GlobalStyles, ThemeProvider, CardMedia, Box, Typography } from '@mui/material';
import { theme } from '../helpers/theme';
import video2 from '../videos/2.mp4';

export default function Store() {
    const navigate = useNavigate();
    const [certificates, setCertificates] = useState([]);
    var shouldFetch = true;
 
    const fetchData = async () => {
        const q = query(collection(db, "certificates"))
        const querySnapshot = await getDocs(q);

        var fetchedCertificates = [];
        querySnapshot.forEach((doc) => {
            const cert = doc.data();
            fetchedCertificates.push(cert);
        });
        setCertificates(fetchedCertificates);
        shouldFetch = false;
    };

    useEffect(() => {
        if (shouldFetch) fetchData();
    // eslint-disable-next-line
    }, [shouldFetch]);

    const productList = certificates.sort((a, b) => a.id > b.id ? 1 : -1)

    const groupedData = productList.reduce((result, item) => {
        if (!result[item.type]) {
          result[item.type] = [];
        }
        result[item.type].push(item);
        return result;
    }, {});

    const openProduct = (item) => {
        navigate('/buy',
            { state: {
                certificate: item
              }
            }
        );
    }

    const productListByGroup = Object.entries(groupedData).map(([type, objects]) => (
        <div key={type}>
            {/* <div className='product-section'> */}
                <Typography variant='h5' sx={{p: '6px', mt: '10px', fontSize: '26pt', backgroundColor: '#ffffffaa', textAlign: 'center'}}>For {type}s:</Typography>
            {/* </div> */}
            <div className='product-section'>
                {objects.map((item) => (
                    <ProductCard key={item.id} certificate={item} clickHandler={() => {openProduct(item)}} />
                ))}
            </div>
        </div>
    ));

    return (
        <ThemeProvider theme={theme}>
            <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
            <CssBaseline />
            <NavigationBar />
            <CardMedia
                    component='video'
                    // className={classes.media}
                    image={video2}
                    autoPlay
                    sx={{ position: 'absolute', maxWidth: '901px', zIndex: '-1', left: '50%',  transform: 'translate(-50%,-0%)'}}
                    />
            
            <Box className='store-section' sx={{mt: '420px'}}>
                <Typography variant='div' className='quote' sx={{p: '6px', fontSize: '26pt', backgroundColor: '#ffffffaa', textAlign: 'center'}}>Which certificate would you like to get?</Typography>
                <Box className='store-section' sx={{mt: '28px'}}>
                {productListByGroup}
                </Box>
            </Box>
            <Footer />
        </ThemeProvider>
    );
}