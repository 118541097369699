import * as React from 'react';
import '../styles/ProductCard.css';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import product1 from '../certificates/asdc.png';
import product2 from '../certificates/asda.png';
import product3 from '../certificates/asde.png';
import parse from 'html-react-parser';

export default function ProductCard(props) {

    const getImageById = (id) => {
        switch (id) {
            case 1:
            case 4:
            case 7:
                return product1;
            case 2:
            case 5: 
            case 8:
                return product2;
            case 3:
            case 6:
            case 9:
                return product3;
            default:
                return product1;
        }
    }

    return (
        
            <Card className='product-card-section' sx={{ minWidth: 250, maxWidth: 274, maxHeight: '380px' }}>
                <CardActionArea onClick={() => {
                        props.clickHandler();
                    }} >
                    <CardMedia
                    component="img"
                    height="160"
                    image={getImageById(props.certificate.id)}
                    alt={props.certificate.title}
                    sx={{display: 'flex', justifySelf: 'flex-start', textAlign: 'center' }}
                    />
                    <CardContent>
                    <Typography gutterBottom variant="h6" component="div">
                        {props.certificate.title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" >
                        {props.certificate.subtitle}
                    </Typography>
                    <Typography variant="body" color="text.tertairy" component="div">
                        {parse("&nbsp;")}
                    </Typography>
                    <Typography variant="body" color="text.tertairy" component="strong">
                        Starting at €{props.certificate.prices[0]} to take the exam once.
                    </Typography>
                    </CardContent>
                </CardActionArea>
            </Card>
    );
}