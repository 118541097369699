import React from 'react';
import { Typography, Link, Button, AppBar, Toolbar, Grid, Box } from '@mui/material';
import { handleLogout, loggedIn } from '../firebase';
import { useNavigate } from 'react-router-dom';

export default function NavigationBar() {
  const navigate = useNavigate();

  const backgroundColor = (process.env.NODE_ENV === 'development') ? 'secondary' : 'primary';
  
  const onLogout = () => {
    handleLogout().then(() => {
      navigate("/login");
      // console.log("Signed out successfully");
    }).catch((error) => {
        console.error("Sign out failed: " + error);
    });
  };

  const goHome = () => {
    navigate("/");
  }

  return (<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start'}}>
          <AppBar
            position="static"
            color={backgroundColor}
            elevation={0}
            sx={{ width: '900px', borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}
          >
            <Toolbar sx={{ display: 'flex', alignContent: 'center'}}>
            <Grid container>
              <Grid item={true} xs={6} sx={{display: 'flex', alignItems: 'center'}}>
                <Typography className="company" variant="div" color="inherit" noWrap onClick={goHome} sx={{ cursor: 'pointer', color: '#333' }}>
                  Scrum Federation
                </Typography>
              </Grid>
              <Grid item={true} xs={6} sx={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>         
                <Link
                  variant="button"
                  color="text.primary"
                  href="/store"
                  sx={{ my: 1, mx: 1.5 }}
                >
                  Store
                </Link>
                <Link
                  variant="button"
                  color="text.primary"
                  href="mailto:certification@scrumfederation.org"
                  sx={{ my: 1, mx: 1.5 }}
                >
                  Support
                </Link>
                
                {loggedIn() ? (
                  <Button onClick={onLogout} variant="outlined" sx={{ my: 1, ml: 1.5, maxWidth: '88px', color: '#333', borderColor: '#333' }}>
                    Logout
                  </Button>
                ):(
                  <Button href="/login" variant="outlined" sx={{ my: 1, ml: 1.5 ,color: '#333', borderColor: '#333' }}>
                    Login
                  </Button>
                )}
              </Grid>
            </Grid>
            </Toolbar>
          </AppBar>
          </Box>);
}