import React, {useState, useEffect} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { createUserWithEmailAndPassword  } from 'firebase/auth';
import { db, auth } from '../firebase';
import { doc, setDoc } from "firebase/firestore";
import uuid from 'react-uuid';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { ThemeProvider } from '@mui/material/styles';
import Footer from '../components/Footer';
import Header from '../components/Header';
import { Alert, Link } from '@mui/material';
import { theme } from '../helpers/theme';

const Signup = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('');
    const [redirect, setRedirect] = useState('');
    const [certificate, setCertificate] = useState(null);
    const [buy, setBuy] = useState(0);
    const [message, setMessage] = useState('');
    const user = auth.currentUser;

    useEffect(() => {
        if (user !== null) {
            navigate("/")
            console.log(user);
        }
    }, [user, navigate]);

    useEffect(() => {
      if (location != null && location.state != null) {
        setRedirect(location.state.redirect || '');
        setCertificate(location.state.certificate || null);
        setBuy(location.state.buy || 0);
        setMessage(location.state.message || '');
      }
    }, [location]);

    const onSubmit = async (e) => {
      e.preventDefault()
     
      await createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            // Signed in
            const user = userCredential.user;
            console.log(user);
            createUserProfile(user);
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorCode, errorMessage);
        });
    }

    const createUserProfile = async (user) => {
        await setDoc(doc(db, "profile", uuid()), {
            user_id: user.uid,
            exam_credits: "{}",
            certificates: [],
            fullName: fullName
        });

        if (redirect !== '') {
          navigate(redirect,
          { state: {
              certificate: certificate,
              buy: buy
            }
          });
        } else {
          navigate("/login");
        }
    }
    const goToLogin = () => {
      if (redirect !== '') {
        navigate("/login",
        { state: {
            redirect: redirect,
            certificate: certificate,
            buy: buy,
            message: message
          }
        });
      } else {
        navigate("/login");
      }
    };
 
  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <Header />
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          { message && 
            <Alert severity="success">{message}</Alert>
          }
          <Avatar sx={{ m: 1, bgcolor: 'primary.main'}}>
            <LockOutlinedIcon sx={{ fill: "white"}} />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign up
          </Typography>
          <Box component="form" onSubmit={onSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
              margin="normal"
              required
              fullWidth
              id="fullName"
              label="Full name (will appear on your certificate)"
              name="fullName"
              autoComplete="given-name"
              onChange={(e) => setFullName(e.target.value)} 
              value={fullName}
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              onChange={(e) => setEmail(e.target.value)} 
              value={email}
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={(e) => setPassword(e.target.value)} 
              value={password}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign Up
            </Button>
            <Grid container>
              <Grid item>
                <Link className="pointer" onClick={goToLogin} variant="body2">
                    Already have an account? Log in
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Footer sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  )
}
 
export default Signup