import { loadStripe } from '@stripe/stripe-js';

let stripePromise;
const getStripe = () => {
  if (!stripePromise) {
    const key = (process.env.NODE_ENV === 'development') ? process.env.REACT_APP_PUBLIC_STRIPE_PUBLISHABLE_KEY_TEST : process.env.REACT_APP_PUBLIC_STRIPE_PUBLISHABLE_KEY_LIVE;
    stripePromise = loadStripe(key);
  }
  return stripePromise;
};

export default getStripe;