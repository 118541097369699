import React from 'react';
import { List, ListItem, Button, Grid, CardMedia } from '@mui/material';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import illustrationBanner from '../images/illustration-banner.png';
import video1 from '../videos/1.mp4';

import { ThemeProvider } from '@mui/material/styles';
import Footer from '../components/Footer';
import { GlobalStyles } from '@mui/material';
import NavigationBar from '../components/NavigationBar';
import TestimonialCarousel from '../components/TestimonialCarousel';
import { theme } from '../helpers/theme';

const faq = [{question: "Why is it so cheap?", answer: "We are trying to keep the costs as low as possible so that it's easy and accessible for everybody to get their certificate. It doesn't have to cost thousands of dollars/ euros. We only test if you have the knowledge that is needed and if you do we'll get you your certificate."},
            {question: "Can I use my certificate on LinkedIn?", answer: "Yes, absolutely. You can download your certificate as a PDF file and upload it to LinkedIn."},
            {question: "Can I really get my certificate in 15 minutes?", answer: "Yes, if you get it on your first try it is absolutely possible to get it within 15 minutes. Most people finish the exam in approximately 10 minutes. "},
            {question: "Why are others so expensive?", answer: "Most of them also sell training courses together with the certification. We don't offer any training. Just the certification."},
            {question: "What type of document will I receive?", answer: "A PDF file."},
            {question: "How much time do I get for the exam?", answer: "15 minutes."},
            {question: "How many questions do I have to answer in the exam?", answer: "30 multiple-choice questions."},
            {question: "Will this give me an edge when applying for new jobs?", answer: "Absolutely, you are more likely to get hired when having certification then when you don't have any certification."},
            {question: "How many questions do I have to answer correctly to get the certificate?", answer: "You have to answer at least 80% of the questions correctly. Right now that would mean that you'll have to answer 24 questions out of the 30 questions correctly."},
            {question: "Should I buy 1, 3 or unlimited credits to get my certificate?", answer: "If you want to be 100% sure to get your certificate you should buy unlimited credits. If you are a bit more of a risk taker 3 credits can also suffice. If you're confident about your skills and know the material through and through 1 credit should be enough."},
            {question: "How do the credits work exactly?", answer: "Each certificate has its own credits and only work on that particular certificate. For example: You buy 'Accredited Scrum Developer CORE' credits. You can only take the 'Accredited Scrum Developer CORE' exam. It will NOT work for the 'Accredited Scrum Developer ADVANCED' exam."},
            {question: "How many people pass the exam on the first try?", answer: "This is a fluid number but so far about 70% passes on the first try, about 90% passes after 3 tries and 100% passes when having unlimited tries."},
            {question: "Do the certificates ever expire like they do at other Scrum Certification companies?", answer: "No, never! We are not going to try and get money out of you each year by expiring your certificate."},
            {question: "Can I just go for ADVANCED or EXPERT without having the previous certificate?", answer: "No, you need to get your CORE certificate before taking the ADVANCED exam and you need the ADVANCED certificate to take the EXPERT exam. This shouldn't be a problem if you already have EXPERT knowledge."},
            {question: "Is it possible to make Scrum compliant with ISO 9001?", answer: "Yes, Scrum can most certainly be made compliant with ISO 9001. You can easily adapt the Scrum processes to meet ISO 9001's quality management requirements with minimal effort."},
        ];

export default function Home() {

    const faqAccordion = faq.map((item, index) => (
        <Accordion key={"accordion-" + index}>
            <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel-header"
            >
            <Typography><b>{item.question}</b></Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Typography>
                {item.answer}
            </Typography>
            </AccordionDetails>
        </Accordion>
    ));

    return (<ThemeProvider theme={theme}>
                <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
                <CssBaseline />
                <NavigationBar />
                {/* <Box
                    component="img"
                    sx={{
                        zIndex: '-11',
                        maxWidth: '100%',
                    }}
                    alt="illustration banner"
                    src={illustrationBanner}
                /> */}
                <CardMedia
                        component='video'
                        className='store-section'
                        image={video1}
                        autoPlay
                        sx={{ maxWidth: '901px', position: 'absolute' , zIndex: '-1', left: '50%',  transform: 'translate(-50%,-0%)'}}
                        />
                {/* <Container maxWidth="md" sx={{ mt: '440px', mb: '10px' }}> */}
                <Container disableGutters maxWidth="md" component="main" sx={{ display: 'flex', flexDirection: 'column', pt: 8, pb: 6, mt: '360px' }}>
                    <Typography variant='div' className='quote' sx={{width: '100%', p: '6px', fontSize: '22pt', backgroundColor: '#ffffffaa', textAlign: 'center', mb: '-20px' }}>Get your Scrum certificate within 15 minutes!</Typography>
                    <Typography variant='h5' sx={{ mt: '76px' }}>We offer Scrum Certificates that validate your knowledge and expertise in Agile project management. Take the first step towards advancing your career by demonstrating your Scrum mastery.</Typography>
                    
                    <Grid container sx={{ mt: '30px', justifyContent: 'center' }}>
                        <Grid item xs={3}>
                            <Button variant='contained' sx={{width: '200px'}} href='/store'>Get certified NOW</Button>
                        </Grid>
                    </Grid>
                    
                    <Typography variant='h5' sx={{mt: '30px'}}><b>Why choose Scrum Federation?</b></Typography>

                    <p>Quick and Convenient Certification: <b>Get certified within 15 minutes</b> if you pass our exam. Our streamlined process allows you to showcase your Scrum skills without lengthy waiting periods.</p>
                    <List sx={{ listStyleType: 'disc', pl: 4 }}>
                        <ListItem key="list1" sx={{ display: 'list-item' }}><b>Affordable Pricing:</b> We believe in making Scrum certifications accessible to everyone. Our certification packages are competitively priced, ensuring you receive exceptional value for your investment.</ListItem>
                        <ListItem key="list2" sx={{ display: 'list-item' }}><b>Enhanced Career Opportunities:</b> A Scrum certificate can open up new career prospects and make you more marketable in Agile-focused industries. It signals to employers that you have the necessary knowledge and skills to contribute effectively to Agile projects, increasing your chances of securing desirable job roles.</ListItem>
                        <ListItem key="list3" sx={{ display: 'list-item' }}><b>Increased Credibility and Professional Recognition:</b> A Scrum certificate provides credibility and validation of your understanding of Scrum principles and practices. It demonstrates your commitment to professional development and can enhance your reputation among colleagues, clients, and employers, making you a trusted and reliable resource in Agile project environments.</ListItem>
                        <ListItem key="list4" sx={{ display: 'list-item' }}><b>Skill Validation and Improved Performance:</b> Scrum certification validates your knowledge of Scrum methodologies and equips you with practical skills to implement Agile frameworks effectively. It enables you to improve project management, team collaboration, and productivity, ultimately leading to more successful project outcomes and delivering value to stakeholders.</ListItem>
                    </List>


                    <Typography variant='h5' sx={{mt: '30px'}}><b>What's in it for me? </b></Typography>
                    <Typography variant='div' sx={{mt: '10px'}}>
                    <p>First and foremost, a Scrum certification demonstrates your <strong>competence</strong> and <strong>expertise</strong> in Agile project management. It validates your understanding of <strong>Scrum principles, methodologies, and frameworks</strong>, giving you a solid foundation to excel in your professional endeavors. Employers and clients value professionals who hold reputable certifications, as it assures them of your capabilities in delivering successful projects.</p>

                    <p>With a Scrum certification, you gain a <strong>competitive edge</strong> in the job market. Employers actively seek individuals who are certified in Scrum, as it signifies their commitment to professional growth and continuous improvement. A certification sets you apart from the competition, increasing your chances of landing sought-after roles and advancing in your career.</p>

                    <p>Certification also enhances your <strong>professional credibility</strong>. It showcases your dedication to staying up-to-date with industry best practices and demonstrates your willingness to invest in your own development. Clients and colleagues alike will have confidence in your abilities, leading to stronger professional relationships and increased trust in your decision-making.</p>

                    <p>Beyond the immediate benefits, a Scrum certification provides you with a solid framework for managing projects in an <strong>Agile environment</strong>. It equips you with the necessary skills to effectively <strong>lead teams</strong>, facilitate <strong>collaboration</strong>, and deliver value to stakeholders. The knowledge gained through certification is applicable across various industries and can be leveraged in both traditional and Agile workplaces.</p>

                    <p>Furthermore, certification offers personal growth and self-improvement. It challenges you to expand your understanding of Scrum, encouraging <strong>continuous learning and development</strong>. By investing in your professional growth, you become a <strong>lifelong learner</strong>, staying ahead of industry trends and evolving practices.</p>

                    <p>Ultimately, a Scrum certification is an investment in your future. It not only enhances your career prospects but also equips you with the knowledge and skills to drive successful projects and contribute to organizational success. It empowers you to make a <strong>positive impact</strong> on your team, your organization, and the projects you undertake.</p>

                    <p>Don't wait any longer. Take the leap and get certified with Scrum Federation today. <strong>Unlock your full potential</strong>, gain <strong>industry recognition</strong>, and embrace the opportunities that come with being a certified Scrum professional. Your journey towards a successful Agile career begins now.</p>
                    </Typography>

                    <Typography variant='h5' sx={{mt: '30px', mb: '20px'}}><b>What our customers say</b></Typography>
                    <center><TestimonialCarousel sx={{mt: '10px'}} /></center>

                    <p>Don't miss out on this exceptional opportunity to propel your career forward. Embrace the <strong>flexibility, affordability, and speed</strong> that Scrum Federation offers. Obtain your Scrum certification today and join the ranks of skilled professionals who have chosen a <strong>smarter, more accessible path to success</strong>.</p>
                    <Typography variant='h5' sx={{mt: '30px', mb: '20px'}}><b>FAQ</b></Typography>
                    {faqAccordion}
                </Container> 
                <Footer />
            </ThemeProvider>);
}