import * as React from 'react';
import { Routes, Route } from 'react-router-dom';
import Certificate from './pages/Certificate';
import Signup from './pages/Signup';
import Login from './pages/Login';
import Reset from './pages/Reset';
import Exam from './pages/Exam';
import Store from './pages/Store';
// import Product from './pages/Product';
import Buy from './pages/Buy';
// import Purchased from './pages/Purchased';
import Home from './pages/Home';

export default function App() {
	return (
		<Routes>
			<Route path="/" element={<Home />} />
			<Route path="/store" element={<Store />} />
			<Route path="/signup" element={<Signup />} />
			<Route path="/login" element={<Login />} />
			<Route path="/reset" element={<Reset />} />
			{/* <Route path="/product" element={<Product />} /> */}
			{/* <Route path="/purchased" element={<Purchased />} /> */}
			<Route path="/buy" element={<Buy />} />
			<Route path="/exam" element={<Exam />} />
			<Route path="/certificate" element={<Certificate />} />
		</Routes>
	);
}
